import React, { Suspense, lazy } from 'react';
import LoadingSpinner from "../components/LoadingSpinner";
import Accordion from "../components/Accordion";

const simulateLazyLoad = (importFunc) => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(importFunc()), 1000); // Simulasi delay 1 detik
    });
  };

const Navbar = lazy(() => simulateLazyLoad(() => import("../components/Navbar")));
const Footer = lazy(() => simulateLazyLoad(() => import('../components/Footer')));

function FAQ () {
    return (
        <div>
            <Suspense fallback={<LoadingSpinner />}>
                <Navbar/>
                <div 
                className='bg-gradient-to-r from-[#EC2027] to-[#1B75BB] content-center pt-36 pb-10'>
                    <h1 className='font-semibold text-center text-cust-42 text-white'>Frequently Asked Questions</h1>
                </div>
                <div className='relative p-10 m-10 rounded-2xl bg-slate-200'>
                    <Accordion/>
                </div>
                <Footer/>
            </Suspense>
        </div>
    );
}

export default FAQ;