import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const Accordion = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const data = [
        {
            title: "Apa itu Beyondtech?",
            content:
                "Beyondtech adalah perusahaan teknologi keuangan di Indonesia yang menghadirkan solusi keuangan berbasis transfer uang. Lebih dari itu, Beyondtech memiliki tujuan yang lebih besar, yaitu ingin terus tumbuh untuk membantu dan melayani individu ataupun bisnis dalam mencapai tujuan keuangan yang dicita-citakan melalui inovasi dan teknologi.",
        },
        {
            title: "Apa saja product Beyondtech?",
            content:
                "Beyondtech adalah solusi bagi Anda yang ingin maju. Kami mempermudah pencapaian tujuan finansial melalui produk seperti Payment Link, Disbursement, dan Remittance.",
        },
        {
            title: "Apa keunggulan Beyondtech?",
            content: [
                "Metode pembayaran tersedia lebih dari 20+ metode seperti e-Wallet, Bank Transfer, Payment Link, Kartu Kredit/Debit.",
                "Efisiensi melalui otomatisasi, pembayaran otomatis melalui API, verifikasi pembayaran, dan laporan keuangan otomatis.",
                "Manajemen Risiko dilengkapi dengan sistem keamanan berlisensi dari otoritas regulasi kelas dunia.",
                "Dukungan 24/7 oleh tim ahli kami untuk segala kebutuhan bahkan di tengah malam.",
                "Integrasi sesuai kebutuhan dengan fleksibilitas plugin dan API yang disesuaikan untuk berbagai platform.",
                "Gratis biaya pendaftaran, transparan, hanya dikenakan biaya per transaksi sukses.",
            ],
        },
        {
            title: "Apa itu Payment Link?",
            content:
                "Payment Link merupakan link yang menerima pembayaran yang memudahkan Anda untuk melakukan pembayaran secara instan.",
        },
        {
            title: "Bagaimana cara kerja Payment Link?",
            content:
                "Anda cukup dengan membuat link lalu bagikan ke pelanggan Anda melalui WhatsApp, SMS, Email, Media Sosial, dan masih banyak lagi.",
        },
        {
            title: "Apa keunggulan Payment Link?",
            content:
                "Anda dapat menerima pembayaran Dengan satu link dan tanpa coding.",
        },
        {
            title: "Metode pembayaran apa yang digunakan Payment Link?",
            content:
                "Payment Link menerima pembayaran melalui Kartu Kredit/Debit, Virtual Account, E-Wallet, Direct Debit.",
        },
        {
            title: "Metode pembayaran apa yang digunakan Payment Link?",
            content:
                "Payment Link menerima pembayaran melalui Kartu Kredit/Debit, Virtual Account, E-Wallet, Direct Debit.",
        },
        {
            title: "Berapa lama waktu proses Payment Link?",
            content:
                "Payment Link merupakan transaksi secara real-time, saat pelanggan Anda sudah menyelesaikan pembayaran, Anda sudah dapat langsung menerima notifikasi pembayaran.",
        },
        {
            title: "Apakah bisa menyesuaikan batas waktu transaksi sesuai kebutuhan?",
            content:
                "Payment Link dapat mengatur dan menyesuaikan batas waktu transaksi sesuai kebutuhan Anda.",
        },
        {
            title: "Apakah bisa terintegrasi dengan API untuk pembuatan Link dalam jumlah banyak?",
            content:
                "Kami menyediakan untuk Integrasi dengan API dengan kebutuhan pembuatan link, dalam jumlah yang banyak dan kirim otomatis tanpa salin dan tempel manual.",
        },
        {
            title: "Apa itu Disbursement?",
            content:
                "Disbursement merupakan fitur Kirim dana ke banyak tujuan untuk segala kebutuhan operasional usaha lebih optimal dan dapat diselesaikan di hari yang sama. Hilangkan kerumitan dan fokuskan pada kesuksesan.",
        },
        {
            title: "Bagaimana Proses Disbursement?",
            content:
                "Disbursement dapat dilakukan dengan cukup sekali konfirmasi untuk kirim ke banyak tujuan, Anda dapat melakukan transfer uang ke banyak tujuan (bulking) atau single transfer.",
        },
        {
            title: "Apakah ada biaya tambahan untuk proses Disbursement?",
            content:
                "Disbursement diproses secara real-time tanpa tertunda dan bayar per pengiriman dana tanpa tambahan biaya.",
        },
        {
            title: "Apa itu Remittance?",
            content:
                "Remittance merupakan Platform pengiriman uang yang terintegrasi dengan seluruh bank yang terhubung dengan principal atau bank switching yang bekerja sama.",
        },
        {
            title: "Apa keunggulan Remittance?",
            content:
                "Remittance kami merupakan cara paling mudah, aman, dan nyaman transfer dana Anda dari Indonesia ke lebih dari 200 Negara.",
        },
        {
            title: "Remittance mendukung pengiriman uang kemana saja",
            content:
                "Remittance mendukung pengiriman dana dari Indonesia ke lebih dari 200 Negara.",
        },
        {
            title: "Berapa lama proses transaksi menggunakan Remittance?",
            content:
                "Remittance merupakan transaksi secara real-time, saat pelanggan Anda sudah menyelesaikan transfer, Anda langsung menerima notifikasi transfer dana.",
        },
        {
            title: "Apakah transaksi menggunakan Remittance aman?",
            content:
                "Selain kurs dan biaya sangat kompetitif, sehingga stabil untuk mengurangi resiko kerugian transaksi remittance kami aman dengan Lisensi Resmi Bank Indonesia.",
        },
        {
            title: "Apabila saya ingin mengirimkan uang ke luar negeri menggunakan Remittance, apakah saya harus ke kantor Beyondtech untuk proses pengiriman dananya?",
            content:
                "Pengiriman uang ke luar negeri lewat remittance 100% Online! Dari mulai registrasi, verifikasi akun, dan pengiriman uangnya semua dapat dilakukan dari tempat duduk Anda saat ini.",
        },
        {
            title: "Apakah transaksi yang sedang dalam proses dapat dibatalkan?",
            content:
                "Transaksi tidak dapat dibatalkan apabila Anda sudah melakukan proses pembayaran. Mohon dapat dipastikan bahwa keseluruhan informasi pengiriman dana ke negara tujuan sudah benar.",
        },
        {
            title: "Apakah terdapat pemberitahuan status transaksi?",
            content:
                "Anda dapat melakukan tracking transaksi dengan mudah. Apabila transaksinya sudah selesai, uang akan sampai ke bank penerima dan Anda juga akan mendapatkan notifikasi transaksi.",
        },
        {
            title: "Berapa lama proses transaksi?",
            content:
                "Perkiraan waktu proses transaksi selesai bervariasi berdasarkan mata uang tujuan, dengan estimasi yaitu dalam menit atau pada hari kerja yang sama. Mohon hubungi Customer Support kami untuk mengetahui informasi lebih lanjut.",
        },
    ];

    // Set the color order
    const colors = ["#F3F7FF", "#FDF8F1", "#FFF5F5"];

    // Add the background color (bgColor) in a cyclic manner
    const dataWithBgColor = data.map((item, index) => ({
        ...item,
        bgColor: colors[index % colors.length], // Use modulo to repeat the color cycle
    }));

    // Filter data based on search term
    const filteredData = dataWithBgColor.filter((item) =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="space-y-4">
            {/* Search Input */}
            <input
                type="text"
                placeholder="Search ..."
                className=" relative w-full p-2 border border-gray-300 rounded-xl mb-4"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />

            {filteredData.map((item, index) => (
                <div
                    key={index}
                    className={`rounded-lg bg-red-500 p-5 ${activeIndex === index ? "space-y-3" : "space-y-1"
                        }`}
                    style={{
                        backgroundColor: item.bgColor,
                    }}
                >
                    <div
                        className="font-bold text-[#505050] cursor-pointer flex justify-between items-center"
                        onClick={() => toggleAccordion(index)}
                        role="button"
                    >
                        <span className="p-2 pr-5">{item.title}</span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`transition-transform duration-500 ${activeIndex === index ? "rotate-180" : ""}`}
                            style={{ pointerEvents: "auto" }}
                        />
                    </div>
                    <div
                        className={`overflow-hidden transition-all ease-in-out`}
                        style={{
                            maxHeight: activeIndex === index ? "500px" : "0",
                            transition: "max-height 0.3s ease",
                        }}
                    >
                        <p className="text-[#505050] mt-2">{item.content}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Accordion;
