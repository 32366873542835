import React, { Suspense, lazy } from 'react';
import LoadingSpinner from "../components/LoadingSpinner";

const simulateLazyLoad = (importFunc) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(importFunc()), 1000); // Simulasi delay 1 detik
  });
};

// Lazy loading untuk komponen
const Navbar = lazy(() => simulateLazyLoad(() => import("../components/Navbar")));
const Home1 = lazy(() => simulateLazyLoad(() => import('../components/Home1')));
const Home2 = lazy(() => simulateLazyLoad(() => import('../components/Home2')));
const Home3 = lazy(() => simulateLazyLoad(() => import('../components/Home3')));
const Home4 = lazy(() => simulateLazyLoad(() => import('../components/Home4')));
const Home5 = lazy(() => simulateLazyLoad(() => import('../components/Home5')));
const Home6 = lazy(() => simulateLazyLoad(() => import('../components/Home6')));
const Home7 = lazy(() => simulateLazyLoad(() => import('../components/Home7')));
const Home8 = lazy(() => simulateLazyLoad(() => import('../components/Home8')));
// const Home9 = lazy(() => simulateLazyLoad(import('../components/Home9')));
const Home10 = lazy(() => simulateLazyLoad(() => import('../components/Home10')));
const Home11 = lazy(() => simulateLazyLoad(() => import('../components/Home11')));
const Home12 = lazy(() => simulateLazyLoad(() => import('../components/Home12')));
// const Home13 = lazy(() => simulateLazyLoad(import('../components/Home13')));
// const Home14 = lazy(() => simulateLazyLoad(import('../components/Home14')));
// const Home15 = lazy(() => simulateLazyLoad(() => import('../components/Home15')));
const Footer = lazy(() => simulateLazyLoad(() => import('../components/Footer')));


function HomeLandingPage() {
  return (
    <div>
      <Suspense fallback={<LoadingSpinner />}>
        <Navbar />
        <Home1 />
        <Home2 />
        <Home3 />
        <Home4 />
        <Home5 />
        <Home6 />
        <Home7 />
        <Home8 />
        <Home10 />
        <Home11 />
        <Home12 />
        {/* <Home13/>
        <Home14/> */}
        {/* <Home15/> */}
        <Footer />
      </Suspense>
    </div>
  );
}

export default HomeLandingPage;

