import HomeLandingPage from './pages/HomeLandingPage';
import FAQ from "./pages/FAQ"
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import React from "react";

function App() {
  return (
    <Router>
            <Routes>
                <Route path='/' element={<HomeLandingPage/>}/>
                <Route path='/FAQ' element={<FAQ/>}/>
            </Routes>
        </Router>
  );
}

export default App;